import localStore from '../LocalStore/LocalStore';
import LogRocket from 'logrocket';

export interface AuthUserDetails {
  email: string;
  name: string;
}

let logrocketUserIdentified = false;

class AuthUser {
  isAuthenticated(): boolean {
    return !!localStore.get('login');
  }

  logout(): void {
    localStore.remove('login');
  }

  setAuthUser(authUser: AuthUserDetails): void {
    localStore.set('authUser', authUser ? JSON.stringify(authUser) : undefined);
    this.setSessionMonitorUser(authUser);
  }

  getAuthUser(): AuthUserDetails {
    const authUserString = localStore.get('authUser');
    const authUser = authUserString ? JSON.parse(authUserString) : undefined;
    this.setSessionMonitorUser(authUser);
    return authUser;
  }

  private setSessionMonitorUser(user: AuthUserDetails): void {
    if (logrocketUserIdentified || !user?.email) {
      return;
    }
    try {
      LogRocket.identify(user.email, {
        name: user.name,
        email: user.email,
      });
      logrocketUserIdentified = true;
    } catch (error) {
      console.log('Logrocket error: ', error);
      console.log('[Warning] not sending session monitoring data to LogRocket.');
    }
  }
}

export default new AuthUser();
